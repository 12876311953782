let eprocMain = {
    init() {
        $(window).scroll(debounce(eprocMain.scroll, 50));

        $('#backTop').click(function () {
            $('html').animate({scrollTop: 0}, 600);
            return false;
        });

        $('#backBottom').click(function () {
            $('html').animate({scrollTop: document.body.clientHeight}, 600);
            return false;
        });
    },
    scroll() {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            $("#backTop").show('fast');
        } else {
            $("#backTop").hide('fast');
        }

        let diff = document.body.clientHeight - document.documentElement.scrollTop

        if (diff > 507) {
            $("#backBottom").show('fast');
        } else {
            $("#backBottom").hide('fast');
        }

    }
};

module.exports = eprocMain;