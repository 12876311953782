function addSpinnerOverlayOn(el) {
    const spinner = $(`
        <div class="text-center mt-5 mb-5">
            <span class="spinner-border text-primary-new" role="status" style="width: 50px;height: 50px;"></span>
            <div><label class="text-primary-new">Carregando...</label></div>
        </div>
    `)

    el.append(spinner)
    return spinner
}

function addSpinnerOverlayOnBody(text = 'Carregando...') {
    let label = $('<label>')
        .text(text)
        .addClass('text-primary-new');
    let divLabel = $('<div>').append(label);
    let spinner = $('<span>')
        .addClass('spinner-border')
        .addClass('text-primary-new')
        .attr('role', 'status')
        .css('margin-top', '300px')
        .css('width', '50px')
        .css('height', '50px');
    let div = $('<div>')
        .addClass('bg-light')
        .addClass('text-center')
        .css('opacity', '0.5')
        .css('top', '0')
        .css('z-index', '2000')
        .css('position', 'fixed')
        .css('width', '100%')
        .css('height', '100%')
        .append(spinner)
        .append(divLabel)
    ;
    $('body').append(div);
    return div;
}

function targetToJqueryEl(target) {
    if (typeof target === 'string') {
        if (target.charAt(0) === '#') {
            target = $(target)
        } else {
            target = $('#' + target)
        }
    }

    return target
}

function submit(opts) {
    let form = $('#' + opts.formId);
    form.on('submit', function (event) {
        event.preventDefault();
        if (form[0].checkValidity()) {
            let spinner = addSpinnerOverlayOnBody('Processando...');
            axios({
                method: 'post',
                url: form.attr('action'),
                data: new FormData(form[0]),
            })
                .then(response => {
                    opts.submitSucessoCallback !== null ? opts.submitSucessoCallback(response) : submitSucessoCallback(response);
                })
                .catch(error => {
                    opts.submitErroCallback !== null ? opts.submitErroCallback(error) : submitErroCallback(error);
                })
                .finally(() => spinner.remove());
        }
    });
}

const delay = '5000';

function submitSucessoCallback(response) {
    eproc.toast.success({
        'text': response.data,
        'data': {'delay': delay}
    });
}

function submitErroCallback(error) {
    let mensagens = [];
    $.each(error.response.data, function (idCampo, msgsCampo) {
        if (idCampo === 'geral') {
            $.each(msgsCampo, function (idGeral, msgGeral) {
                mensagens.push(msgGeral);
            });
        } else {
            mensagens = $.merge(mensagens, msgsCampo);
        }
    });
    let opts = {
        'text': mensagens.join('<br>'),
        'data': {'delay': delay}
    };
    error.response.status === 501
        ? eproc.toast.warning(opts)
        : eproc.toast.error(opts);
}


module.exports = {
    load: (targetEl, url, data) => {
        targetEl = targetToJqueryEl(targetEl)
        targetEl.html('')
        let spinner = addSpinnerOverlayOn(targetEl)
        return targetEl.load(url, data, () => spinner.remove())
    },
    submit: submit,
    submitSucessoCallback: submitSucessoCallback,
    submitErroCallback: submitErroCallback,
}