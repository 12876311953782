const navbar = require('./navbar')
const eprocMain = require('./eproc.main')
import menu from '#eproc/menu'
import toast from '#eproc/toast'
import modal from '#eproc/modal.js'

const acessibility = require('./acessibilidade.js')

export default {
    ajax: require('./eproc/ajax.js'),
    hint: require('./hint.js'),
    main: eprocMain,
    menu: menu,
    modal: modal,
    navbar: navbar,
    tabs: require('./eproc/tabs'),
    toast: toast,
    init: () => {
        navbar.init()
        eprocMain.init()
        menu.init()
        acessibility.init()
    },
}