let navbar = {
    getElement: function () {
        return $('#navbar');
    },
    exists: function () {
        return navbar.getElement().length !== undefined;
    },
    getHeight: function () {
        return navbar.getElement().height();
    },
    init: function () {
        function focarProcesso() {
            document.getElementById('txtNumProcessoPesquisaRapida').focus()
        }
        $(document).bind('keydown','Ctrl+Shift+f',focarProcesso);

        function abrirPaginaInicial() {
            document.getElementById('btn-home').click()
        }
        $(document).bind('keydown','Ctrl+Shift+h',abrirPaginaInicial);

        function abrirMeusLocalizadores() {
            document.getElementById('btn-meus-localizadores').click()
        }
        $(document).bind('keydown','Alt+shift+m',abrirMeusLocalizadores);

        function abrirPerfilUsuario() {
            window.setTimeout(function () {
                document.getElementById('btnProfile').focus();
            }, 0);
        }
        $(document).bind('keydown','Ctrl+shift+U',abrirPerfilUsuario);

        function encerrarSessao(){
            var mensagem = 'Deseja encerrar a sess\u00E3o?';

            if (!window.confirm(mensagem)) {
                return false;
            } else {
                document.getElementById('btn-encerrar-sessao').click()
            }
        }
        $(document).bind('keydown','Ctrl+Shift+F4',encerrarSessao);
    },
};

module.exports = navbar;